import React, { useState } from "react";
import { IoIosArrowForward, IoIosArrowDown } from "react-icons/io";

export function useArrow() {
  const [isDown, setIsDown] = useState(false);
  const a = <IoIosArrowForward />;
  const b = <IoIosArrowDown />;
  const arrow = isDown ? b : a;
  return { arrow, setIsDown };
}
