import React, { useState, useEffect } from "react";
import Layout from "../components/layout";
import useIncidents from "../hooks/useIncidents";
import { DataContainer, colors } from "../style/theme";
import useLocale from "../hooks/useLocale";
import TableFilters from "../components/data/table-filters";
import Timeline from "../charts/timeline";
import IncidentCard from "../components/data/incidents-card";
import useTranslation from "../hooks/useTranslations";
import SEO from "../components/seo";
import Pagination from "rc-pagination";
import Map from "../components/map";
import "../style/pagination.css";
import { slice } from "ramda";
import { mq } from "../utils/helper";
const PER_PAGE = 20;

export default function Data() {
  const { locale } = useLocale();
  const { incidents, setFilters, filters, isLoading } = useIncidents();

  const [currentPage, setCurrentPage] = useState(1);
  const [hoveredUnit, setHoveredUnit] = useState("IIIII");
  const tr = useTranslation();
  const { isAra } = useLocale();

  return (
    <Layout>
      <SEO metadata={{ title: "Data" }} />
      <div
        css={{
          backgroundColor: colors.backgroundColor,
          direction: isAra ? "rtl" : "ltr",
        }}
      >
        <DataContainer
          css={{
            paddingTop: "4rem",
            [mq[0]]: {
              display: "none",
            },
          }}
        >
          <div
            css={{
              maxHeight: "6rem",
            }}
          >
            <Timeline
              lang={locale}
              incidents={incidents}
              width={1200}
              height={100}
              isLoading={false}
            />
          </div>
        </DataContainer>
        <DataContainer css={{ display: "block" }}>
          <TableFilters
            setFilters={setFilters}
            filters={filters}
            setCurrentPage={setCurrentPage}
          />
          <small
            css={{
              [mq[0]]: {
                margin: "1rem",
              },
            }}
          >
            {incidents?.length || 0} {tr("Results")}
          </small>
        </DataContainer>
        <DataContainer css={{ position: "relative" }}>
          <div>
            {isLoading && (
              <IncidentCard
                isLoading={isLoading}
                key={"loading"}
                incident={{}}
              />
            )}
            {slice(
              PER_PAGE * (currentPage - 1),
              PER_PAGE * (currentPage - 1) + PER_PAGE,
              incidents || []
            ).map((incident) => {
              return (
                <IncidentCard
                  incident={incident}
                  key={incident?.id}
                  setHoveredUnit={setHoveredUnit}
                />
              );
            })}
          </div>
          <div
            css={{
              position: "sticky",
              top: "0",
              willChange: "transform",
              height: "100vh",
              boxSizing: "border-box",
            }}
          >
            <Map
              incidents={incidents}
              viewport={{
                lat: 15,
                lng: 46,
                zoom: 7,
              }}
            />
          </div>
        </DataContainer>
        <DataContainer>
          <Pagination
            current={currentPage}
            pageSize={20}
            total={incidents?.length}
            onChange={(current) => setCurrentPage(current)}
          />
        </DataContainer>
      </div>
    </Layout>
  );
}
